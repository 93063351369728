import classNames from 'classnames';
import { Logo as LogoComponent } from 'components/logo/logo.component';
import { ScreenSize, useScreenSize } from 'hooks/use-screen-size.hook';
import { useMemo } from 'react';

export const LogoFooter = () => {
  const { screenSize } = useScreenSize();

  const logoClassName = useMemo(() => 'fill-white-300', []);

  const Logo = useMemo(() => {
    const isSmall = screenSize <= ScreenSize.Small;
    const isLarge = screenSize <= ScreenSize.Large;

    if (isSmall) return <LogoComponent size="big" oab className={logoClassName} />;
    if (isLarge) return <LogoComponent size="big" oab text="bottom" className={logoClassName} />;

    return <LogoComponent size="big-wrapped" oab text="bottom" className={logoClassName} />;
  }, [logoClassName, screenSize]);

  return (
    <section
      className={classNames(
        'col-3 align-self-center d-flex justify-content-center align-items-center',
        // Small
        'col-sm-6 min-vw-sm-100',
        // Large
        'min-vw-lg-auto',
        // Extra Large
        'col-xl-4'
      )}
    >
      {Logo}
    </section>
  );
};
