import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCookies } from 'hooks/use-cookies.hook';
import { ISendEmail } from 'interfaces/api/send-email.interface';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendEmail } from 'utils/api/send-email';
import { phoneMask } from 'utils/masks/phone.mask';
import { Button } from '../../../components/form/button.component';
import { Checkbox } from '../../../components/form/checkbox.component';
import { Input } from '../../../components/form/input.component';
import { TextArea } from '../../../components/form/text-area.component';
import { RoutePath } from '../../../navigation/route-path';
import { contactFormValidation } from './contact-form.validation';
import { generateCookieInfo } from './cookie-info';

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setCookie } = useCookies();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ISendEmail>({
    resolver: yupResolver(contactFormValidation),
  });

  const handleFormSubmit = useCallback(
    async (data: ISendEmail) => {
      try {
        setIsLoading(true);
        await sendEmail(data);
        toast.success('E-mail enviado com sucesso!');
        reset();
        setCookie('contactFormSubmission', generateCookieInfo(data), { path: '/' });
      } catch (error) {
        toast.error('Erro ao enviar e-mail, tente novamente mais tarde!');
        console.error('error: ', error);
      } finally {
        setIsLoading(false);
      }
    },
    [reset, setCookie]
  );

  return (
    <section className="flex-fill w-100 d-flex justify-content-center align-items-center p-3">
      <form className="d-flex flex-column bg-primary p-3 shadow-lg">
        <Controller
          name="fullName"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label="Nome completo"
              autoComplete="name"
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isLoading}
            />
          )}
        />
        <div
          className={classNames(
            'row w-100 align-self-center flex-fill',
            // Medium
            'flex-md-nowrap gap-md-2'
          )}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="email"
                label="Email"
                autoComplete="email"
                containerClassName={classNames(
                  'col-12 p-0 flex-fill',
                  // Medium
                  'col-md-6'
                )}
                onChange={onChange}
                value={value}
                error={error?.message}
                disabled={isLoading}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label="Telefone"
                autoComplete="tel"
                containerClassName={classNames(
                  'col-12 p-0 flex-fill',
                  // Medium
                  'col-md-6'
                )}
                onChange={onChange}
                value={phoneMask(value)}
                error={error?.message}
                inputMask={phoneMask}
                maxLength={16}
                disabled={isLoading}
              />
            )}
          />
        </div>
        <Controller
          name="subject"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              label="Assunto"
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isLoading}
            />
          )}
        />
        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextArea
              label="Mensagem"
              onChange={onChange}
              value={value}
              error={error?.message}
              disabled={isLoading}
            />
          )}
        />

        <div
          className={classNames(
            'row w-100 align-self-center flex-fill',
            // Medium
            'flex-md-nowrap gap-md-2'
          )}
        >
          <Controller
            name="privacyPolicy"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Checkbox
                label="privacy policy"
                containerClassName={classNames(
                  'col-12 p-0 flex-fill',
                  // Medium
                  'col-md-10'
                )}
                onChange={onChange}
                checked={value}
                error={error?.message}
                disabled={isLoading}
              >
                <>
                  Ao marcar esta caixa, você concorda com nossa{' '}
                  <Link to={RoutePath.PrivacyPolicy}>Política de Privacidade</Link> e o uso de seus
                  dados pessoais para os fins indicados neste formulário.
                </>
              </Checkbox>
            )}
          />

          <Button
            onClick={handleSubmit(handleFormSubmit)}
            containerClassName={classNames(
              'col-12 p-0 flex-fill',
              // Medium
              'col-md-4',
              // Large
              'col-lg-2'
            )}
            isLoading={isLoading}
            disabled={!!Object.keys(errors).length || isLoading}
          >
            Enviar
          </Button>
        </div>
      </form>
    </section>
  );
};
