import { ReactComponent as Ellipse } from 'assets/others/ellipse.svg';
import { useScreenSize } from 'hooks/use-screen-size.hook';

export const PrivacyPolicyBackground = () => {
  const array = new Array(75).fill(0);
  const { screenSize } = useScreenSize();

  return (
    <section className="position-absolute top-0 left-0 w-100 pe-none">
      {array.map((_, index) => {
        const randomNumber = Math.ceil(Math.random() * index * (Math.random() * 20));
        const size = randomNumber > 20 ? randomNumber : 20 * (Math.random() * 10);

        const x = screenSize * Math.random();
        const y = (screenSize / (index || 1)) * Math.random() * (index % 2 === 0 ? 1 : -1);
        const styles = {
          transform: `translate(${x}px, ${y}px)`,
        };

        return (
          <div className={`w-100 ${screenSize * Math.random()}`} style={styles}>
            <Ellipse height={size} width={size} />
          </div>
        );
      })}
    </section>
  );
};
