import { ReactComponent as BackgroundLines } from 'assets/others/active-item-background-lines.svg';
import classNames from 'classnames';

import { ButtonHTMLAttributes, useMemo } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  isLoading: boolean;
  error?: string;
  containerClassName?: string;
}

export const Button = ({
  children,
  isLoading = false,
  error = '',
  containerClassName,
  ...rest
}: ButtonProps) => {
  const buttonId = useMemo(
    () => `button-${children.replaceAll(' ', '-').toLowerCase()}`,
    [children]
  );

  return (
    <div className={classNames('custom-btn mt-3', containerClassName)}>
      <button
        id={buttonId}
        className="btn bg-white rounded-0 text-body2 text-primary text-uppercase w-100 h-100 position-relative"
        title={isLoading ? 'Carregando' : 'Enviar'}
        {...rest}
      >
        <span className={classNames({ 'opacity-50': isLoading })}>{children}</span>
        {isLoading && (
          <BackgroundLines className="offcanvas-background-lines position-absolute stroke-primary pe-none top-0 start-0 h-100 w-100" />
        )}
      </button>
    </div>
  );
};
