import BackgroundImageDesktop from 'assets/images/brandi-redd-aJTiW00qqtI-unsplash-desktop.webp';
import BackgroundImageMobile from 'assets/images/brandi-redd-aJTiW00qqtI-unsplash-mobile.webp';
import { ITeamCompanyPageInfo } from 'interfaces/company/pages-info.company.interface';
import { employeesInfo } from './employees-info.component';

export const teamPageInfo: ITeamCompanyPageInfo = {
  title: 'Equipe',
  id: 'equipe',
  displayOnNavbar: true,
  employees: employeesInfo,
  backgroundImages: {
    mobileSource: BackgroundImageMobile,
    desktopSource: BackgroundImageDesktop,
    altText: 'Folhas espalhadas de papel branco cobrindo todo o quadro.',
  },
};
