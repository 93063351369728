import classNames from 'classnames';
import { SectionBackground } from 'components/section-background/section-background.component';
import { useEffect, useMemo, useState } from 'react';
import { companyInfo } from 'utils/company';

const TIME_MS = 5000; // 5 seconds in milliseconds

export const Initial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const texts = useMemo(() => companyInfo.pages.initial.texts, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Cycles through each carousel item using this to implement the active class
      // This is needed because of a bug, the carousel won't start itself in mobile
      // devices, so this logic creates the cycle and together with the fade in animation
      // mimics the carousel fade action from bootstrap.
      setActiveIndex((index) => (index === texts.length - 1 ? 0 : index + 1));
    }, TIME_MS);

    return () => clearInterval(interval);
  }, [texts, activeIndex]);

  return (
    <section
      id={companyInfo.pages.initial.id}
      className="d-flex justify-content-center align-items-center"
    >
      <SectionBackground backgroundImages={companyInfo.pages.initial.backgroundImages} />
      <div
        className={classNames(
          'carousel text-center mx-2',
          // Small
          'mx-sm-2'
        )}
      >
        <div className="carousel-inner">
          {texts.map((text, index) => (
            <div
              key={text.toLowerCase().replaceAll(' ', '-')}
              className={classNames(
                'carousel-item fs-h4 lh-base text-white font-serif',
                // Small
                'fs-sm-h2',
                // Large
                'fs-lg-h1',
                // Logic
                { active: index === activeIndex }
              )}
            >
              {text}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
