export const checkTimeBetweenTwoTimes = (
  date: Date,
  startTime: string,
  closeTime: string
): boolean => {
  const startDate = new Date(date.getTime());
  startDate.setHours(+startTime.split(':')[0]);
  startDate.setMinutes(+startTime.split(':')[1]);
  startDate.setSeconds(0);

  const endDate = new Date(date.getTime());
  endDate.setHours(+closeTime.split(':')[0]);
  endDate.setMinutes(+closeTime.split(':')[1]);
  endDate.setSeconds(0);

  return startDate <= date && endDate >= date;
};
