import { useEventListener } from 'hooks/use-event-listener.hook';
import { useCallback, useState } from 'react';

// Bootstrap default breakpoints
export enum ScreenSize {
  XSmall = 0,
  Small = 576,
  Medium = 768,
  Large = 992,
  XLarge = 1200,
  XXLarge = 1400,
  XXXLarge = 2000,
  XXXXLarge = 2450,
}

interface IScreenSize {
  screenSize: ScreenSize;
  screenWidth: number;
}

const getScreenSize = (screenWidth: number): ScreenSize => {
  if (screenWidth >= ScreenSize.XLarge) return ScreenSize.XLarge;
  if (screenWidth >= ScreenSize.Large) return ScreenSize.Large;
  if (screenWidth >= ScreenSize.Medium) return ScreenSize.Medium;
  if (screenWidth >= ScreenSize.Small) return ScreenSize.Small;

  return ScreenSize.XSmall;
};

export const useScreenSize = (): IScreenSize => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenSize, setScreenSize] = useState(getScreenSize(window.innerWidth));

  const handleResize = useCallback(() => {
    const size = getScreenSize(window.innerWidth);
    if (size !== screenSize) {
      setScreenSize(size);
    }

    if (window.innerWidth !== screenWidth) {
      setScreenWidth(window.innerWidth);
    }
  }, [screenSize, screenWidth]);

  useEventListener('resize', handleResize);

  return {
    screenSize,
    screenWidth,
  };
};
