import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import classNames from 'classnames';
import { useScrollPosition } from 'hooks/use-scroll-position.hook';
import { useCallback, useMemo, useState } from 'react';
import { companyInfo } from 'utils/company';
import { openLink } from 'utils/open-link';

export const SocialButtons = () => {
  const [isPlusButtonClicked, setIsPlusButtonClicked] = useState<boolean>(false);

  const { isScrollOnTop } = useScrollPosition();

  const handlePlusClick = useCallback(() => {
    setIsPlusButtonClicked((state) => !state);
  }, []);

  const innerButtons = useMemo(
    () => [
      {
        title: 'Instagram',
        icon: companyInfo.socials.instagram.icon,
        action: () => openLink(companyInfo.socials.instagram.url),
      },
      {
        title: 'Whatsapp',
        icon: companyInfo.socials.whatsapp.icon,
        action: () => openLink(companyInfo.socials.whatsapp.url),
      },
    ],
    []
  );

  return (
    <section
      onMouseLeave={() => setIsPlusButtonClicked(false)}
      className={classNames(
        'social-buttons position-fixed bottom-0 ps-3 pb-4',
        // Medium
        'ps-md-5 pb-md-5',
        { 'social-buttons--top start-0': isScrollOnTop }
      )}
    >
      <section
        className={classNames(
          'social-buttons--inner-buttons d-flex flex-column align-items-center gap-5',
          { 'pe-none': !isPlusButtonClicked || !isScrollOnTop },
          { 'social-buttons--inner-buttons-active pe-auto': isPlusButtonClicked || isScrollOnTop },
          { 'position-absolute': isScrollOnTop }
        )}
      >
        {innerButtons.map(({ icon: Icon, action, title }, index) => (
          <button
            key={title + index}
            className={classNames('btn bg-white p-2 rounded-0 shadow-lg', {
              'transform-rotate-45': isScrollOnTop,
            })}
            title={title}
            onClick={action}
          >
            <Icon className="fill-primary transform-rotate-315" />
          </button>
        ))}
      </section>

      <button
        onClick={handlePlusClick}
        className={classNames(
          'social-buttons--main-button btn bg-white p-2 rounded-0 shadow-lg',
          { 'transform-rotate-45': !isPlusButtonClicked },
          { 'h-0 opacity-0': isScrollOnTop }
        )}
      >
        <PlusIcon className="fill-primary transform-rotate-315" />
      </button>
    </section>
  );
};
