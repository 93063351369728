import { useEventListener } from 'hooks/use-event-listener.hook';
import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const position = window.scrollY;
    setScrollPosition(position);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  useEventListener('scroll', handleScroll, { passive: true });

  /**
   * This function calculates the scroll percentage relative to a specific element.
   *
   * @param {RefObject<HTMLElement | null>} elementRef - Reference to the element for which to calculate the scroll percentage.
   * @param {number} scrollOffset - The scroll offset value to adjust the timing of the scroll animation. Negative values delay the animation, while positive values speed it up. Default is -0.5.
   * @returns {number} The scroll percentage, a value between 0 and 1 indicating the scroll progress.
   */
  const getScrollPercentageFromElement = useCallback(
    (elementRef: RefObject<HTMLElement | null>, scrollOffset = -0.5) => {
      // Check if the element reference exists
      if (!elementRef.current) return 0;

      // Get the height of the element
      const elementHeight = elementRef.current.clientHeight;

      // Check if the element has no height
      if (elementHeight === 0) return 0;

      // Calculate the scroll value based on the current scroll position and element height.
      // The scroll value represents the progress of the scroll animation.
      // Subtracting 1 ensures that the value starts from zero when the element is reached.
      const scrollValue = (scrollPosition + elementHeight) / elementHeight - 1 + scrollOffset;

      // Calculate the scroll percentage based on the scroll value.
      // Ensure the value is between 0 and 1 using Math.max and Math.min.
      const scrollPercentage = Math.max(0, Math.min(1, scrollValue));

      return scrollPercentage;
    },
    [scrollPosition]
  );

  const isScrollOnTop = useMemo(() => scrollPosition === 0, [scrollPosition]);

  return { scrollPosition, isScrollOnTop, getScrollPercentageFromElement };
};
