import { ICompanyWorkField } from 'interfaces/company/work-field.company.interface';

export const companyWorkFieldsInfo: ICompanyWorkField[] = [
  {
    name: 'Direito Imobiliário',
    description:
      'É o ramo que trata da propriedade. Nossos serviços incluem questões relacionadas a compra e venda de imóveis, locação, regularização, a análise de contratos, solução de disputas de posse ou propriedade, elaboração de pareceres e orientação jurídica em casos de desapropriação, usucapião, condomínio e incorporação imobiliária.',
    initials: 'I',
  },
  {
    name: 'Direito Patrimonial',
    description:
      'É o ramo que trata da gestão e proteção patrimonial da pessoa física ou jurídica. Nossos serviços incluem questões relacionadas com planejamento sucessório, gestão de patrimônio e proteção de bens, testamentos, inventários, holdings familiares, doações e estruturação de negócios.',
    initials: 'P',
  },
  {
    name: 'Direito de Família e Sucessões',
    description:
      'É o ramo que trata das situações envolvendo a família. Nossos serviços incluem questões relacionadas a divórcios, partilhas, reconhecimento de paternidade, guarda de filhos, alimentos, adoção, tutelas e curatelas.',
    initials: 'FS',
  },
  {
    name: 'Direito Ambiental',
    description:
      'É o ramo que trata da proteção do meio ambiente e da sustentabilidade. Nossos serviços incluem questões relacionadas a projetos de licenciamento ambiental, intervenções em áreas ambientalmente protegidas, responsabilidade civil ambiental, adequações demandadas pelo Poder Público.',
    initials: 'A',
  },
];
