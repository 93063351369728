import AnaPaulaBoeingPictureDesktop from 'assets/team/ana-paula-boeing-desktop.webp';
import AnaPaulaBoeingPictureMobile from 'assets/team/ana-paula-boeing-mobile.webp';
import AndreOsmarZocatelliPictureDesktop from 'assets/team/andre-osmar-zocatelli-desktop.webp';
import AndreOsmarZocatelliPictureMobile from 'assets/team/andre-osmar-zocatelli-mobile.webp';
import IsmarLombardiJuniorPictureDesktop from 'assets/team/ismar-lombardi-junior-desktop.webp';
import IsmarLombardiJuniorPictureMobile from 'assets/team/ismar-lombardi-junior-mobile.webp';
import MenetiAdrielleKanzlerPictureDesktop from 'assets/team/meneti-adrielle-kanzler-desktop.webp';
import MenetiAdrielleKanzlerPictureMobile from 'assets/team/meneti-adrielle-kanzler-mobile.webp';
import SanclerSoaresAdrianoLombardiPictureDesktop from 'assets/team/sancler-soares-adriano-lombardi-desktop.webp';
import SanclerSoaresAdrianoLombardiPictureMobile from 'assets/team/sancler-soares-adriano-lombardi-mobile.webp';

import { IEmployeeInfo } from 'interfaces/employee/employee.interface';
import { companySocialsInfo } from '../socials.company';

export const employeesInfo: IEmployeeInfo[] = [
  {
    name: 'Ana Paula Boeing',
    role: 'Advogada',
    image: {
      mobile: AnaPaulaBoeingPictureMobile,
      desktop: AnaPaulaBoeingPictureDesktop,
      alt: 'Retrato sério da advogada Ana Paula Boeing em seu ambiente de trabalho',
    },
    socials: {
      instagram: {
        url: 'https://www.instagram.com/anapboeing/',
        icon: companySocialsInfo.instagram.icon,
      },
    },
  },
  {
    name: 'Sancler Soares Adriano Lombardi',
    role: 'Advogado',
    image: {
      mobile: SanclerSoaresAdrianoLombardiPictureMobile,
      desktop: SanclerSoaresAdrianoLombardiPictureDesktop,
      alt: 'Retrato sério do advogado Sancler Soares Adriano Lombardi em seu ambiente de trabalho',
    },
    socials: {
      instagram: {
        url: 'https://www.instagram.com/lombardisancler/',
        icon: companySocialsInfo.instagram.icon,
      },
    },
  },
  {
    name: 'André Osmar Zocatelli',
    role: 'Advogado',
    image: {
      mobile: AndreOsmarZocatelliPictureMobile,
      desktop: AndreOsmarZocatelliPictureDesktop,
      alt: 'Retrato sério do advogado André Osmar Zocatelli em seu ambiente de trabalho',
    },
    socials: {
      instagram: {
        url: 'https://www.instagram.com/andrezocatelli.adv/',
        icon: companySocialsInfo.instagram.icon,
      },
    },
  },

  {
    name: 'Ismar Lombardi Júnior',
    role: 'Advogado',
    image: {
      mobile: IsmarLombardiJuniorPictureMobile,
      desktop: IsmarLombardiJuniorPictureDesktop,
      alt: 'Retrato sério do advogado Ismar Lombardi Júnior em seu ambiente de trabalho',
    },
    socials: {
      instagram: {
        url: 'https://www.instagram.com/ismarlombardi/',
        icon: companySocialsInfo.instagram.icon,
      },
    },
  },
  {
    name: 'Mêneti Adrielle Kanzler',
    role: 'Assessora',
    image: {
      mobile: MenetiAdrielleKanzlerPictureMobile,
      desktop: MenetiAdrielleKanzlerPictureDesktop,
      alt: 'Retrato sério da assessora Mêneti Adrielle Kanzler em seu ambiente de trabalho',
    },
  },
];
