/**
 * This function is a shortcut for link openeners without
 * the whole syntax.
 *
 * @param {string} url The url that will be open.
 * @param {string} target The target option. Defaults to '_blank'.
 * @param {string} features The features option. Defaults to 'noreferrer'.
 * @returns
 */
export const openLink = (url: string, target: string = '_blank', features: string = 'noreferrer') =>
  window.open(url, target, features);
