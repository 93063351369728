import BackgroundImageDesktop from 'assets/images/michael-olsen-kezCkGe73jY-unsplash-desktop.webp';
import BackgroundImageMobile from 'assets/images/michael-olsen-kezCkGe73jY-unsplash-mobile.webp';
import { IWorkFieldsPageInfo } from 'interfaces/company/pages-info.company.interface';

export const workFieldsPageInfo: IWorkFieldsPageInfo = {
  title: 'Áreas de Atuação',
  id: 'areas-de-atuacao',
  displayOnNavbar: true,
  backgroundImages: {
    mobileSource: BackgroundImageMobile,
    desktopSource: BackgroundImageDesktop,
    altText: "Fotografia aérea de corpo d'água.",
  },
};
