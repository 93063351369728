import classNames from 'classnames';
import { InputHTMLAttributes, useCallback, useMemo } from 'react';

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  children?: JSX.Element;
  error?: string;
  onChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
}

export const Checkbox = ({
  label,
  onChange,
  children = <></>,
  error = '',
  containerClassName,
  ...rest
}: CheckBoxProps) => {
  const handleSetcheckBoxValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange]
  );

  const checkBoxId = useMemo(() => `checkbox-${label.replaceAll(' ', '-').toLowerCase()}`, [label]);

  return (
    <div className={classNames('custom-checkbox mt-3', containerClassName)}>
      <div className="position-relative">
        <div className="form-check d-flex gap-2 align-items-center">
          <input
            className={classNames(
              'form-check-input flex-shrink-0 rounded-0 border border-1 border-white',
              { 'cursor-pointer': !rest.disabled }
            )}
            type="checkbox"
            id={checkBoxId}
            onChange={handleSetcheckBoxValue}
            {...rest}
          />
          <label
            className={classNames(
              'form-check-label',
              { 'text-white': !error },
              { 'text-danger': !!error }
            )}
            htmlFor={checkBoxId}
          >
            {children ? children : label}
          </label>
        </div>
      </div>

      <p
        className={classNames('invalid-feedback d-block fs-body4 opacity-0 pe-none w-0', {
          'opacity-100 pe-auto w-100': !!error,
        })}
      >
        {error}
      </p>
    </div>
  );
};
