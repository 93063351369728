import classNames from 'classnames';
import { TextareaHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  onChange: (data: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string;
  containerClassName?: string;
}

export const TextArea = ({
  label,
  onChange,
  error = '',
  containerClassName,
  ...rest
}: TextAreaProps) => {
  const [textAreaValue, setTextAreaValue] = useState<string>();
  const [istextAreaEmpty, setIstextAreaEmpty] = useState<boolean>(false);

  const handleSetTextAreaValue = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e);
      setTextAreaValue(e.target.value);
    },
    [onChange]
  );

  const textAreaId = useMemo(
    () => `text-area-${label.replaceAll(' ', '-').toLowerCase()}`,
    [label]
  );

  useEffect(() => {
    setIstextAreaEmpty(!!textAreaValue);
  }, [textAreaValue]);

  return (
    <div
      className={classNames('custom-text-area mt-3', containerClassName)}
      data-active={istextAreaEmpty}
    >
      <div className="position-relative">
        <label
          htmlFor={textAreaId}
          className={classNames(
            'form-label position-absolute m-0 d-flex align-items-center fs-body2 pe-none',
            { 'text-primary-500': !istextAreaEmpty },
            { 'fs-body4 fw-bold text-white pe-auto h-auto': istextAreaEmpty }
          )}
        >
          {label}
        </label>
        <textarea
          id={textAreaId}
          className={classNames(
            'form-control rounded-0 text-primary border border-1 border-transparent',
            { 'border-danger': !!error }
          )}
          value={textAreaValue}
          onChange={handleSetTextAreaValue}
          aria-labelledby={textAreaId}
          {...rest}
        />
      </div>

      <p
        className={classNames('invalid-feedback d-block fs-body4 opacity-0 pe-none w-0', {
          'opacity-100 pe-auto w-100': !!error,
        })}
      >
        {error}
      </p>
    </div>
  );
};
