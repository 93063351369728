import { ReactComponent as CookieImage } from 'assets/others/cookie.svg';
import { useCookies } from 'hooks/use-cookies.hook';
import { RoutePath } from 'navigation/route-path';
import { Link } from 'react-router-dom';

export const CookieConsent = () => {
  const { setConsent, hasConsent } = useCookies();

  return (
    <>
      {hasConsent === undefined && (
        <div className="cookie-consent position-fixed bottom-0 end-0 mw-50 ">
          <div className="position-relative bg-secondary-100 text-white p-3 shadow-sm d-flex flex-column gap-2">
            <div className="cookie-consent-text bg-secondary-100 rounded ps-2 pt-2 w-fit-content d-flex flex-column gap-2">
              <p className="m-0 fs-6">
                Este site utiliza cookies para oferecer a melhor experiência possível.
              </p>
              <p className="m-0 fs-6">
                Ao clicar em "Aceitar", você concorda com o uso de cookies conforme descrito em
                nossa <Link to={RoutePath.PrivacyPolicy}>Política de Privacidade</Link>.
              </p>
            </div>
            <button
              onClick={() => setConsent(true)}
              className="mt-2 btn bg-white rounded-0 text-body2 fw-bold text-secondary-100 text-uppercase w-100 h-100 position-relative"
            >
              ACEITAR
            </button>
            <CookieImage className="cookie-consent-image position-absolute" />
          </div>
        </div>
      )}
    </>
  );
};
