import * as yup from 'yup';

export const contactFormValidation = yup
  .object()
  .shape({
    fullName: yup.string().required('Necessário o nome completo.'),
    email: yup.string().email('Deve ser um email válido.').required('Necessário o email.'),
    phone: yup
      .string()
      .required('Necessário o número de telefone.')
      .matches(/^\(\d{2}\) \d \d{4}-\d{4}$/, 'Formato inválido de telefone.'),
    subject: yup.string().required('Necessário escrever um assunto.'),
    message: yup.string().required('Necessário escrever uma mensagem.'),
    privacyPolicy: yup.boolean().oneOf([true], 'Necessário aceitar os termos de compromisso.'),
  })
  .required();
