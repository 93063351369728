/**
 * This function receives a string and
 * returns the initials of the words in that string
 *
 * ```typescript
 * getInitials("Hello woRld") => "HW";
 * ```
 *
 * @param {string} str
 * @returns {string} The first letter of each blank spaced
 * word in the received string.
 */
export const getInitials = (str: string): string =>
  str
    // Divide into words
    .split(' ')
    // Get the first string of each word
    .map((word) => word[0].toUpperCase())
    // Join the strings into a non-spaced word
    .join('');
