import { ReactComponent as HamburgerAndCloseIcon } from 'assets/icons/hamburger-and-close.svg';
import { ReactComponent as ActiveItemBackgroundLines } from 'assets/others/active-item-background-lines.svg';
import { ReactComponent as OffcanvasBackgroundLines } from 'assets/others/offcanvas-background-lines.svg';
import classNames from 'classnames';
import { Logo } from 'components/logo/logo.component';
import { ScreenSize, useScreenSize } from 'hooks/use-screen-size.hook';
import { useScrollPosition } from 'hooks/use-scroll-position.hook';
import { ICompanyPageInfo } from 'interfaces/company/pages-info.company.interface';
import { RoutePath } from 'navigation/route-path';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyInfo } from 'utils/company';

const OFFCANVAS_SHOW_SELECTOR = '.offcanvas.show';
const OFFCANVAS_BACKDROP_SELECTOR = '.offcanvas-backdrop';
const BUTTON_NAV_TOGGLER_SELECTOR = 'button.navbar-toggler';

export const Navbar = () => {
  const { screenSize } = useScreenSize();
  const navigate = useNavigate();
  const { isScrollOnTop } = useScrollPosition();

  useEffect(() => {
    // This logic removes the remanescent offcanvas backdrop when changing layouts
    // from the opened offcanvas to a layout without the
    const offcanvasBackdrop = document.querySelector(OFFCANVAS_BACKDROP_SELECTOR);

    if (offcanvasBackdrop) {
      const offcanvas = document.querySelector(OFFCANVAS_SHOW_SELECTOR);
      if (!offcanvas) offcanvasBackdrop.remove();
    }
  }, [screenSize]);

  /**
   * This function handles the links callback when the offcanvas is open
   */
  const navLinkCallback = useCallback(
    (elementId: string, isNavbarBrand = false) => {
      const { pathname } = window.location;

      if (pathname !== RoutePath.Home) return navigate(RoutePath.Home);

      // Fetches the button offcanvas toggler
      const buttonToggler = document.querySelector<HTMLButtonElement>(BUTTON_NAV_TOGGLER_SELECTOR);
      // Fetches the clicked element id in the page
      const element = document.getElementById(elementId);

      let shouldClickButton = true;

      if (isNavbarBrand) {
        // If the element is the navbar brand, check if offcanvas with show class is visible
        const offcanvasShow = document.querySelector(OFFCANVAS_SHOW_SELECTOR);
        // Set the visibility of that element into the shoud click variable
        shouldClickButton = !!offcanvasShow;
        // That way the navbar brand will only close the toggle menu, not open
      }

      // Click the button toggler
      if (buttonToggler && shouldClickButton && screenSize <= ScreenSize.Medium)
        buttonToggler.click();
      // Scroll to the desired element
      if (element) window.scrollTo({ top: element.offsetTop });
      // Change the url params to meet the element id
      window.history.replaceState(null, '', `#${elementId}`);
    },
    [screenSize, navigate]
  );

  const anchorLinks = useMemo((): JSX.Element => {
    const sections: ICompanyPageInfo[] = Object.values(companyInfo.pages);

    return (
      <>
        {sections.map(
          ({ title, id, displayOnNavbar }, index) =>
            displayOnNavbar && (
              <li
                key={index + id}
                className="nav-item position-relative"
                onClick={() => navLinkCallback(id)}
              >
                <ActiveItemBackgroundLines
                  className={classNames(
                    'active-item-background-lines position-absolute h-100 w-100 top-0 left-0 stroke-white pe-none opacity-25',
                    // Large
                    { 'd-lg-none': isScrollOnTop }
                  )}
                />
                <a
                  className={classNames(
                    'nav-link text-white fs-h5',
                    // Large
                    { 'text-lg-primary': isScrollOnTop }
                  )}
                  aria-current="page"
                  href={`#${id}`}
                >
                  {title}
                </a>
              </li>
            )
        )}
      </>
    );
  }, [navLinkCallback, isScrollOnTop]);

  return (
    <nav
      id="navbar"
      className={classNames('navbar fixed-top bg-primary shadow', {
        'navbar--top bg-transparent shadow-none': isScrollOnTop,
      })}
    >
      <div
        className={classNames('container-fluid h-100 gap-3', {
          'position-relative justify-content-end': isScrollOnTop,
        })}
      >
        <div
          className={classNames(
            'navbar-brand d-flex align-items-center justify-content-center shadow-lg',
            { 'bg-primary position-absolute top-0 p-4 shadow-lg': isScrollOnTop }
          )}
          onClick={() => navLinkCallback(companyInfo.pages.initial.id, true)}
        >
          <Logo size="small" className="fill-white" />
        </div>

        <button
          className="navbar-toggler h-100 d-lg-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <HamburgerAndCloseIcon className="hamburger-and-close-icon h-100 fill-white" />
        </button>
        <div
          {...(screenSize <= ScreenSize.Medium && {
            id: 'offcanvasNavbar',
            tabIndex: Number(-1),
            'aria-labelledby': 'offcanvasNavbarLabel',
            className: 'border-0 offcanvas offcanvas-end bg-primary ',
          })}
        >
          <OffcanvasBackgroundLines className="offcanvas-background-lines d-lg-none position-absolute stroke-primary-100 pe-none opacity-25" />
          <ul
            className={classNames(
              'navbar-nav flex-fill d-flex align-items-center overflow-y-scroll py-4',
              // Medium
              'overflow-y-md-auto py-md-0',
              // Logic
              { 'flex-column justify-content-start': screenSize <= ScreenSize.Medium },
              { 'flex-row justify-content-end gap-4': screenSize > ScreenSize.Medium }
            )}
          >
            {anchorLinks}
          </ul>
        </div>
      </div>
    </nav>
  );
};
