import BackgroundImageDesktop from 'assets/images/jay-mantri-TFyi0QOx08c-unsplash-desktop.webp';
import BackgroundImageMobile from 'assets/images/jay-mantri-TFyi0QOx08c-unsplash-mobile.webp';
import { IInitialCompanyPageInfo } from 'interfaces/company/pages-info.company.interface';

export const companyInitialPageTexts: string[] = [
  'Protegendo seus direitos imobiliários, ambientais e sucessórios.',
  'Defendendo seus interesses em questões imobiliárias, ambientais e sucessórias.',
  'Comprometidos com a proteção de seus direitos no setor imobiliário, ambiental e sucessório.',
  'Negociamos seus contratos e protegemos seus investimentos imobiliários.',
  'Defendemos o meio ambiente e seus direitos em questões ambientais.',
  'Cuidamos de seus bens e interesses, garantindo a segurança patrimonial de sua família.',
  'Assessoria jurídica completa para compra, venda, locação e regularização de imóveis.',
  'Atuamos em prol do desenvolvimento sustentável, preservando o meio ambiente e seus recursos naturais.',
  'Planejamento sucessório personalizado para garantir a tranquilidade de seus herdeiros.',
];

export const initialPageInfo: IInitialCompanyPageInfo = {
  title: 'Início',
  id: 'inicio',
  displayOnNavbar: true,
  texts: companyInitialPageTexts,
  backgroundImages: {
    mobileSource: BackgroundImageMobile,
    desktopSource: BackgroundImageDesktop,
    altText: 'Palmeiras cobertas de neblina.',
  },
};
