import { ICompanyPeriodsStartAndEndTimes } from 'interfaces/company/working-hours.company.interface';
import { checkTimeBetweenTwoTimes } from './check-time-between-two-times';

export const checkBusinessHoursTime = (
  date: Date,
  {
    morning: { startTime: morningStartTime, closeTime: morningCloseTime },
    afternoon: { startTime: afternoonStartTime, closeTime: afternoonCloseTime },
  }: ICompanyPeriodsStartAndEndTimes
): boolean => {
  const morningBusinessHours = checkTimeBetweenTwoTimes(date, morningStartTime, morningCloseTime);
  const afternoonBusinessHours = checkTimeBetweenTwoTimes(
    date,
    afternoonStartTime,
    afternoonCloseTime
  );

  return morningBusinessHours || afternoonBusinessHours;
};
