import BackgroundImageDesktop from 'assets/images/pexels-sora-shimazaki-5668464-desktop.webp';
import BackgroundImageMobile from 'assets/images/pexels-sora-shimazaki-5668464-mobile.webp';
import { IContactPageInfo } from 'interfaces/company/pages-info.company.interface';

export const contactPageInfo: IContactPageInfo = {
  title: 'Contato',
  id: 'contato',
  displayOnNavbar: true,
  backgroundImages: {
    mobileSource: BackgroundImageMobile,
    desktopSource: BackgroundImageDesktop,
    altText:
      'Homem e mulher anônimos em elegantes casacos cumprimentando-se na rua durante uma reunião no centro da cidade.',
  },
};
