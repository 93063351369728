import classNames from 'classnames';
import { companyInfo } from 'utils/company';

export const Contact = () => {
  return (
    <section
      className={classNames(
        'col-3 d-flex flex-column text-white gap-2',
        // Small
        'col-sm-3',
        // Large
        'col-lg-2'
      )}
    >
      <h4 className="text-uppercase">Contato</h4>

      <a target="_blank" rel="noreferrer" href={companyInfo.address.url}>
        {companyInfo.address.value.map((addressValue, index) => (
          <p key={index + addressValue}>{addressValue}</p>
        ))}
      </a>

      <a href={`tel:${companyInfo.socials.whatsapp.number}`}>
        {companyInfo.socials.whatsapp.number}
      </a>

      <a href={`mailto:${companyInfo.socials.email}`}>{companyInfo.socials.email}</a>

      <a href={companyInfo.client.manual.filePath} download={companyInfo.client.manual.fileName}>
        Manual do Cliente
      </a>
    </section>
  );
};
