import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import classNames from 'classnames';

interface ISliderArrow {
  orientation?: 'left' | 'right';
  disabled: boolean;
  containerClass?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  style?: React.CSSProperties;
}

export const SliderArrow = ({
  containerClass,
  disabled = false,
  orientation = 'left',
  onClick,
  style = {},
}: ISliderArrow) => {
  return (
    <div
      className={classNames(
        'slider-arrow d-flex align-items-center justify-content-center max-vh-100 flex-grow-1 position-relative',
        // Medium
        'flex-md-grow-0',
        containerClass
      )}
      style={style}
      data-disabled={disabled}
      onClick={(e) => (disabled ? () => {} : onClick(e))}
    >
      <div className="h-100 w-100 bg-gray-500 position-absolute top-0 right-0 bottom-0 left-0"></div>
      <ArrowIcon
        className={classNames(
          'fill-white-300 m-2',
          { 'transform-rotate-0': orientation === 'left' },
          { 'transform-rotate-180': orientation === 'right' }
        )}
      />
    </div>
  );
};
