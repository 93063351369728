import { CookieConsent } from 'components/cookie-consent/cookie-consent.component';
import { Navbar } from 'components/navbar/navbar.component';
import { PrivacyPolicyBackground } from './components/background.component';

export const PrivacyPolicyPage = () => {
  return (
    <>
      <section className="position-relative overflow-hidden">
        <Navbar />
        <section style={{ marginTop: '200px' }} className="d-flex flex-column gap-2 p-4">
          <h2 className="fs-1">POLÍTICA DE PRIVACIDADE DE DADOS</h2>
          <hr />
          <p>
            O presente termo tem a finalidade de esclarecer a nossa política de coleta, tratamento e
            compartilhamento de dados, nos termos da LGPD.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">1. CONSENTIMENTO DO TITULAR</h4>
          <p>
            Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para
            coletarmos e tratarmos dados sobre você quando necessários à prestação de nossos
            serviços, nos seguintes termos:
          </p>
          <ol style={{ listStyle: 'lower-alpha' }} className="d-flex flex-column gap-2">
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Coletamos os dados fornecidos por você no contato, tais como qualificação civil,
                endereço de e-mail, bem como outras informações de contato on-line ou número de
                telefone e demais informações requeridas no cadastro.
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada
                com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro
                meio.
              </span>
            </li>
          </ol>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">2. FINALIDADES: UTILIZAÇÃO DESTAS INFORMAÇÕES</h4>
          <p>
            Ao utilizar nosso website e canais de comunicação via redes sociais você AUTORIZA
            EXPRESSAMENTE o uso das informações fornecidas para as seguintes FINALIDADES:
          </p>
          <ol style={{ listStyle: 'lower-alpha' }} className="d-flex flex-column gap-2">
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para
                melhorar a qualidade dos nossos serviços, mediante sua personalização ao usuário
                final.
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Obrigações legais. É permitido o compartilhamento dos seus dados com terceiros para
                cumprir exigências legais, regulatórias ou fiscais, em relação aos tribunais,
                agências reguladoras ou agências governamentais.
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Comunicação. As informações coletadas, mormente em formulário, serão utilizadas para
                contatá-lo, quando pertinente, ao exemplo de uma solicitação de orçamento ou
                negociação corrente.
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                Logística e arquivamento. As informações coletadas poderão ser mantidas pela empresa
                por período indeterminado, enquanto forem essenciais para a sua logística interna e
                registros de arquivo, ressalvado o direito à exclusão e demais previstos no artigo
                17 e seguintes da LGPD.
              </span>
            </li>
          </ol>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">3. COMPARTILHAMENTO DE DADOS</h4>
          <p>
            Os seus dados não serão compartilhados com terceiros, via de regra, salvo em atendimento
            às hipóteses legais, bem como nos sistemas internos de controladoria do Escritório.
          </p>
          <p>
            Ademais, o compartilhamento pode objetivar o cumprimento de exigências legais,
            regulatórias ou fiscais, no que tange tribunais, agências reguladoras ou governamentais.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">4. ARMAZENAMENTO E TRATAMENTO DE SEUS DADOS</h4>
          <p>
            Os dados coletados são armazenados em serviços em nuvem (Microsoft OneDrive e webmail),
            prestados por empresas renomadas, nos quais a segurança dos dados é assegurada, para
            fins de garantir a proteção no caso de uma falha ou outra complicação.
          </p>
          <p>
            O tratamento se dará em conformidade às finalidades informadas no presente termo, sendo
            lícito ao controlador coletar, produzir, receptar, classificar, utilizar, acessar,
            reproduzir, transmitir, distribuir, processar, arquivar, armazenar, eliminar, avaliar ou
            controlar a informação, modificar, comunicar, transferir, difundir ou extrair dados do
            usuário.
          </p>
          <p>
            Ademais, o armazenamento e tratamento de dados se dará em conformidade às disposições da
            LGPD, mediante consentimento expresso e para as finalidades impostas pelo presente
            termo.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">
            5. RESPONSABILIDADES DO CONTROLADOR E MEDIDAS DE SEGURANÇA
          </h4>
          <p>
            Todos os dados fornecidos são tratados unicamente para atingir as finalidades acima
            listadas. Estes dados são armazenados em servidores seguros nossos ou de fornecedores
            contratados, acessados e utilizados de acordo com nossas políticas e padrões de
            segurança. Todo o tráfego contido em nossos servidores ou entre o seu computador e
            nossos servidores é encriptado através do protocolo seguro HTTPS, SSL ou semelhante.
          </p>
          <p>
            Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que
            os dados que coletamos sejam tratados com segurança onde quer que estejam localizados.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">
            6. POR QUANTO TEMPO VAMOS ARMAZENAR SUAS INFORMAÇÕES
          </h4>
          <p>
            Nós manteremos as informações coletadas até que ocorra a solicitação de exclusão
            definitiva ou deixem de ser pertinentes para o funcionamento logístico e prestação de
            serviços do Controlador. Neste caso, nós cessaremos imediatamente a utilização dos seus
            dados, porém poderemos armazená-los enquanto tenhamos obrigações legais, contábeis,
            tributárias ou judiciais a cumprir com tais dados.
          </p>
          <p>
            A manutenção dos dados após a solicitação de exclusão somente se dará na ocorrência de
            alguma das condições previstas no artigo 16 da LGPD.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">7. DO ENCARREGADO PELOS DADOS</h4>
          <p>
            A gestão dos seus dados é feita pela empresa Lombardi & Boeing Advogados Associados,
            estabelecida à rua Coronel Procópio Gomes de Oliveira, 210, sala 02, bairro Centro, no
            município de Jaraguá do Sul/SC, CEP 89.251-201 e você pode entrar em contato com o
            Encarregado dos seus dados, Sr. Ismar Lombardi Junior, através das informações contidas
            no website, sem prejuízo do contato direto a Autoridade de Proteção de Dados
            brasileira., ou mediante o e-mail{' '}
            <a href="mailto:atendimento@lombardiboeing.com.br">atendimento@lombardiboeing.com.br</a>
            .
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">8. DIREITOS DO TITULAR DOS DADOS</h4>
          <p>
            O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do
            titular por ele tratados, a qualquer momento e mediante requisição:
          </p>
          <ol style={{ listStyle: 'lower-alpha' }} className="d-flex flex-column gap-2">
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                a exclusão parcial ou integral dos seus dados pessoais, nos termos da legislação;
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                a alteração ou correção de dados pessoais, quando ocorrerem em incorreção ou em
                demais casos pertinentes;
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                impor objeções, limites ou restrições ao uso de seus dados, quando estes não
                estiverem de acordo com o consentimento e finalidade dispostos no presente termo de
                política de privacidade de dados e na legislação cabível;
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">
                o acesso a uma cópia dos dados tratados por meio eletrônico, em formato legível;
              </span>
            </li>
            <li className="ms-4 fw-bold">
              <span className="fw-normal">demais direitos listados no artigo 18 da LGPD.</span>
            </li>
          </ol>
          <p>
            As requisições do titular devem ser apresentadas aos contatos elencados no item 9 do
            presente termo.
          </p>

          <h4 className="fs-5 mt-3 mb-1 fw-bold">
            9. COMO ACESSAR E CONTROLAR SUAS INFORMAÇÕES PESSOAIS
          </h4>
          <p>
            Você pode solicitar informações, alteração, esclarecimentos ou exclusão de seus dados
            via e-mail, ao endereço{' '}
            <a href="mailto:atendimento@lombardiboeing.com.br">atendimento@lombardiboeing.com.br</a>
            , bem como na seção de contato do website, mediante formulário. Vamos atender
            imediatamente às solicitações, nos termos da Lei Geral da Proteção de Dados.
          </p>

          <PrivacyPolicyBackground />
        </section>
      </section>
      <CookieConsent />
    </>
  );
};
