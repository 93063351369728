import { ISendEmail } from 'interfaces/api/send-email.interface';

export const generateCookieInfo = (data: ISendEmail): Record<string, any> => {
  const timestamp = new Date().toISOString();
  const userAgent = navigator.userAgent;

  return {
    timestamp,
    formFields: {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
    },
    userAgent,
  };
};
