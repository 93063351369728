import classNames from 'classnames';
import { IBackgroundImages } from 'interfaces/company/pages-info.company.interface';
import { useEffect, useState } from 'react';
import { SectionBackground } from './section-background.component';

interface ISectionCarouselBackground {
  backgroundImages: IBackgroundImages[];
}

const TIME_MS = 10000; // 10 seconds in milliseconds

export const SectionCarouselBackground = ({ backgroundImages }: ISectionCarouselBackground) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Cycles through each carousel item using this to implement the active class
      // This is needed because of a bug, the carousel won't start itself in mobile
      // devices, so this logic creates the cycle and together with the fade in animation
      // mimics the carousel fade action from bootstrap.
      setActiveIndex((index) => (index === backgroundImages.length - 1 ? 0 : index + 1));
    }, TIME_MS);

    return () => clearInterval(interval);
  }, [backgroundImages, activeIndex]);

  return (
    <>
      <div className="carousel position-absolute top-0 left-0 w-100 h-100">
        <div className="carousel-inner w-100 h-100">
          {backgroundImages.map((images, index) => (
            <div
              className={classNames(
                'carousel-item  w-100 h-100',
                // Logic
                { active: index === activeIndex }
              )}
            >
              <SectionBackground backgroundImages={images} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
