import { CookieConsent } from 'components/cookie-consent/cookie-consent.component';
import { Navbar } from 'components/navbar/navbar.component';
import { SocialButtons } from 'components/social-buttons/social-buttons.component';
import { useIntersectionObserver } from 'hooks/use-intersection-observer.hook';
import { ScreenSize, useScreenSize } from 'hooks/use-screen-size.hook';
import { useMemo } from 'react';
import { companyInfo } from 'utils/company';
import { AboutUs } from './about-us/about-us.page-component';
import { Contact } from './contact/contact.page-component';
import { Footer } from './footer/footer.page-component';
import { Initial } from './initial/initial.page-component';
import { Team } from './team/team.page-component';
import { WorkFields } from './work-fields/work-fields.page-component';

export const LandingPage = () => {
  const { activeSection } = useIntersectionObserver();
  const { screenSize } = useScreenSize();

  const isActiveSection = useMemo(() => {
    const isMobileScreenSize = screenSize < ScreenSize.Medium;

    return {
      workFields: activeSection === companyInfo.pages.workFields.id && isMobileScreenSize,
      team: activeSection === companyInfo.pages.team.id && isMobileScreenSize,
      contact: activeSection === companyInfo.pages.contact.id && isMobileScreenSize,
    };
  }, [activeSection, screenSize]);

  return (
    <>
      <Navbar />
      <section
        data-bs-spy="scroll"
        data-bs-target="#navbar"
        data-bs-smooth-scroll="true"
        className="overflow-x-hidden"
      >
        <Initial />
        <AboutUs />
        <WorkFields isActive={isActiveSection.workFields} />
        <Team isActive={isActiveSection.team} />
        <Contact isActive={isActiveSection.contact} />
        <Footer />
      </section>
      <SocialButtons />
      <CookieConsent />
    </>
  );
};
