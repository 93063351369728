import ComputerAndBookDesktop from 'assets/images/lb-computer-and-book-desktop.webp';
import ComputerAndBookMobile from 'assets/images/lb-computer-and-book-mobile.webp';
import LobbyDesktop from 'assets/images/lb-lobby-desktop.webp';
import LobbyMobile from 'assets/images/lb-lobby-mobile.webp';
import OfficeDeskDesktop from 'assets/images/lb-office-desk-desktop.webp';
import OfficeDeskMobile from 'assets/images/lb-office-desk-mobile.webp';
import VaseAndBookDesktop from 'assets/images/lb-vase-and-book-desktop.webp';
import VaseAndBookMobile from 'assets/images/lb-vase-and-book-mobile.webp';

import { IAboutUsPageInfo } from 'interfaces/company/pages-info.company.interface';

export const aboutUsPageInfo: IAboutUsPageInfo = {
  title: 'Quem somos',
  id: 'quem-somos',
  displayOnNavbar: true,
  backgroundImages: [
    {
      mobileSource: OfficeDeskMobile,
      desktopSource: OfficeDeskDesktop,
      altText: 'Escritório da Lombardi & Boeing.',
    },
    {
      mobileSource: LobbyDesktop,
      desktopSource: LobbyMobile,
      altText: 'Lobby do escritório da Lombardi & Boeing.',
    },
    {
      mobileSource: ComputerAndBookDesktop,
      desktopSource: ComputerAndBookMobile,
      altText: 'Escritório da Lombardi & Boeing.',
    },
    {
      mobileSource: VaseAndBookDesktop,
      desktopSource: VaseAndBookMobile,
      altText: 'Escritório da Lombardi & Boeing.',
    },
  ],
};
