import classNames from 'classnames';
import { ICompanyWorkField } from 'interfaces/company/work-field.company.interface';

export const WorkField = ({ name, description, initials }: ICompanyWorkField) => {
  return (
    <section className="work-field text-white d-flex align-items-center ms-2">
      <section
        className={classNames(
          'work-field--initials d-flex align-items-center justify-content-center',
          'flex-grow-0 flex-shrink-0 position-relative font-serif text-uppercase',
          'm-2 me-4 fs-h5 opacity-75',
          // Medium
          'fs-md-h1 me-md-5'
        )}
      >
        <p className="mt-2 mt-md-4">{initials}</p>
      </section>
      <section className="work-field--details bg-primary-300 opacity-75 position-relative p-2">
        <div className="d-flex flex-column h-100">
          <h4
            className={classNames(
              'work-field--title font-serif fs-body3 lh-base mb-1',
              // Medium
              'fs-md-h4 mb-md-3',
              // Large
              'fs-lg-h3 mb-lg-3'
            )}
          >
            {name}
          </h4>
          <p
            className={classNames(
              'work-field--description font-sans-serif-fallback fs-body4 overflow-y-auto h-100 pe-2',
              // Medium
              'fs-md-body1'
            )}
          >
            {description}
          </p>
        </div>
      </section>
    </section>
  );
};
