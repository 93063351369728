import classNames from 'classnames';
import { SectionBackground } from 'components/section-background/section-background.component';
import { TitleContainer } from 'components/title-container/title-container';
import { companyInfo } from 'utils/company';
import { TeamSlider } from './team-slider.component';

interface ITeam {
  isActive: boolean;
}

export const Team = ({ isActive }: ITeam) => {
  return (
    <section
      id={companyInfo.pages.team.id}
      className={classNames(
        'd-flex justify-content-center align-items-center h-fit-content position-relative gap-2',
        { 'pt-8': isActive }
      )}
    >
      <SectionBackground backgroundImages={companyInfo.pages.team.backgroundImages!} />
      <TitleContainer
        title={companyInfo.pages.team.title}
        position="end"
        className="min-vh-100"
        contentClassName={classNames(
          'd-flex align-items-center justify-content-center align-content-center overflow-hidden',
          { 'pt-8': isActive }
        )}
        titleClassName={classNames({ 'pt-8': isActive })}
      >
        <TeamSlider companyInfo={companyInfo} />
      </TitleContainer>
    </section>
  );
};
