import { CookieConsent } from 'components/cookie-consent/cookie-consent.component';
import { SectionBackground } from 'components/section-background/section-background.component';
import { RoutePath } from 'navigation/route-path';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { companyInfo } from 'utils/company';

const TIME_MS = 5000; // 5 seconds in milliseconds

export const NoMatchPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const texts = useMemo(() => companyInfo.pages.initial.texts, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Cycles through each carousel item using this to implement the active class
      // This is needed because of a bug, the carousel won't start itself in mobile
      // devices, so this logic creates the cycle and together with the fade in animation
      // mimics the carousel fade action from bootstrap.
      setActiveIndex((index) => (index === texts.length - 1 ? 0 : index + 1));
    }, TIME_MS);

    return () => clearInterval(interval);
  }, [texts, activeIndex]);

  return (
    <>
      <section
        id={companyInfo.pages.noMatch.id}
        className="position-relative d-flex justify-content-center align-items-center overflow-hidden"
      >
        <SectionBackground backgroundImages={companyInfo.pages.noMatch.backgroundImages} />
        <section className="content position-relative d-flex flex-column gap-4 text-primary p-4">
          <section className="d-flex flex-column gap-2">
            <h1 className="font-sans-serif fw-bold">{companyInfo.pages.noMatch.title}</h1>
            <h2 className="font-sans-serif fw-bold fs-h5 fs-sm-h3">Oops! Página não encontrada.</h2>
            <p className="fw-bold fs-body3 fs-sm-h5">
              A página que você está procurando pode ter sido removida ou não existe.
            </p>
          </section>
          <Link to={RoutePath.Home} className="fs-body3 fs-sm-h5 align-self-end">
            Voltar a página inicial.
          </Link>
        </section>
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-secondary-500 rounded opacity-75"></div>
      </section>
      <CookieConsent />
    </>
  );
};
