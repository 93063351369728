import axios, { CreateAxiosDefaults } from 'axios';
import { SERVICE_ENDPOINT } from 'utils/env';

const ApiConfig: CreateAxiosDefaults = {
  baseURL: SERVICE_ENDPOINT,
  headers: {
    Accept: 'application/json; charset=utf-8',
    'Content-Type': 'application/json; charset=UTF-8',
  },
};

const Api = axios.create(ApiConfig);

export const ApiPost = async <T>(endpoint: string, data: T) => {
  return await Api.post(endpoint, data);
};
