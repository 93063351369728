import { ReactComponent as AboutUsBackgroundLinesLeft } from 'assets/others/about-us-background-lines-left.svg';
import { ReactComponent as AboutUsBackgroundLinesRight } from 'assets/others/about-us-background-lines-right.svg';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

interface AboutUsBackgroundLinesProps {
  scrollPercentage: number;
}

export const AboutUsBackgroundLines = ({ scrollPercentage }: AboutUsBackgroundLinesProps) => {
  const backgroundLinesLeftRef = useRef<SVGSVGElement>(null);
  const backgroundLinesRightRef = useRef<SVGSVGElement>(null);

  const getSVGPathInfo = useCallback((element: React.RefObject<SVGSVGElement>) => {
    // Classes for stroke and fill elements
    const strokeClasses = 'stroke-primary';
    const fillClasses = 'fill-white';

    // Get the stroke path element inside the SVG
    const pathStrokeElement = element.current?.querySelector<SVGPathElement>('path.stroke')!;
    // Get the total length of the stroke path, or default to 0 if unavailable
    const strokePathLength = pathStrokeElement?.getTotalLength?.() || 0;
    // Set the stroke dash array to the total length, effectively setting the start position of the drawing
    pathStrokeElement.style.strokeDasharray = String(strokePathLength);
    // Hide the stroke element by offsetting the dash
    pathStrokeElement.style.strokeDashoffset = String(strokePathLength);
    // Add stroke classes to the stroke path element
    strokeClasses.split(' ').forEach((className) => pathStrokeElement.classList.add(className));

    // Get the fill path element inside the SVG
    const pathFillElement = element.current?.querySelector<SVGPathElement>('path.fill')!;
    // Add fill classes to the fill path element
    fillClasses.split(' ').forEach((className) => pathFillElement.classList.add(className));

    // Return the stroke path element and its total length
    return {
      strokeElement: pathStrokeElement,
      strokeLength: strokePathLength,
    };
  }, []);

  useEffect(() => {
    // Iterate over the background lines elements and update their stroke dash offset.
    [backgroundLinesLeftRef, backgroundLinesRightRef].forEach((elementRef) => {
      const { strokeElement, strokeLength } = getSVGPathInfo(elementRef);

      // Calculate the new stroke dash offset based on the length and scroll percentage.
      const draw = strokeLength * scrollPercentage;

      // Update the stroke dash offset of the element.
      strokeElement.style.strokeDashoffset = String(strokeLength - draw);
    });
  }, [getSVGPathInfo, scrollPercentage]);

  return (
    <>
      <AboutUsBackgroundLinesLeft
        ref={backgroundLinesLeftRef}
        preserveAspectRatio="none"
        className={classNames(
          'background-lines background-lines-left position-absolute h-100 pe-none top-0',
          // Extra Large
          'start-xxl-0'
        )}
      />
      <AboutUsBackgroundLinesRight
        ref={backgroundLinesRightRef}
        preserveAspectRatio="none"
        className={classNames(
          'background-lines background-lines-right position-absolute h-100 pe-none top-0',
          // Extra Large
          'end-xxl-0'
        )}
      />
    </>
  );
};
