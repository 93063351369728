import classNames from 'classnames';
import { EmployeeCard } from 'components/employee-card/employee-card.component';
import { SliderArrow } from 'components/slider/slider-arrow.component';
import { useEventListener } from 'hooks/use-event-listener.hook';
import { ScreenSize, useScreenSize } from 'hooks/use-screen-size.hook';
import { ICompanyInfo } from 'interfaces/company/info.company.interface';
import { useCallback, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/types';

interface ITeamSlider {
  companyInfo: ICompanyInfo;
}

export const TeamSlider = ({ companyInfo }: ITeamSlider) => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper | null>(null);
  const [slidesPerView, setSlidesPerView] = useState<number>(1);
  const [isFirstOrLastIndex, setIsFirstOrLastIndex] = useState<'first' | 'last' | null>('first');
  const [arrowHeight, setArrowHeight] = useState<number>(0);

  const { screenWidth } = useScreenSize();

  const hadleSlidesPerViewSetter = useCallback(() => {
    if (screenWidth >= ScreenSize.XXXXLarge) setSlidesPerView(5);
    else if (screenWidth >= ScreenSize.XXXLarge) setSlidesPerView(4);
    else if (screenWidth >= ScreenSize.XLarge) setSlidesPerView(3);
    else if (screenWidth >= ScreenSize.Large) setSlidesPerView(2);
    else setSlidesPerView(1);
  }, [screenWidth]);

  useEffect(() => {
    hadleSlidesPerViewSetter();
    setArrowHeight(swiperInstance?.height || 0);
  }, [swiperInstance, swiperInstance?.height, hadleSlidesPerViewSetter, screenWidth]);

  useEventListener('resize', () => {
    hadleSlidesPerViewSetter();
    setArrowHeight(swiperInstance?.height || 0);
  });

  const handlePrevSlide = useCallback(() => {
    swiperInstance?.slidePrev();
  }, [swiperInstance]);

  const handleNextSlide = useCallback(() => {
    swiperInstance?.slideNext();
  }, [swiperInstance]);

  return (
    <section
      className={classNames(
        'd-flex align-items-center justify-content-center align-content-center flex-wrap overflow-hidden m-4 gap-1',
        // Medium
        'flex-md-nowrap mx-md-0'
      )}
    >
      <SliderArrow
        containerClass={classNames(
          'order-1',
          // Medium
          'ms-md-4 order-md-0'
        )}
        style={{ height: `${arrowHeight}px` }}
        disabled={isFirstOrLastIndex === 'first'}
        onClick={handlePrevSlide}
      />
      <SwiperComponent
        spaceBetween={8}
        slidesPerView={slidesPerView}
        onTransitionEnd={(swiper: Swiper) => {
          const height = swiper.slides[swiper.activeIndex]?.getBoundingClientRect().height;
          setArrowHeight(height);
        }}
        onSlideChange={(swiper: Swiper) => {
          const activeIndex = swiper.activeIndex;

          if (activeIndex === 0) setIsFirstOrLastIndex('first');
          else if (activeIndex + slidesPerView === swiper.slides.length)
            setIsFirstOrLastIndex('last');
          else setIsFirstOrLastIndex(null);
        }}
        onSwiper={(swiper: Swiper) => setSwiperInstance(swiper)}
      >
        {companyInfo.pages.team.employees.map((employee, index) => (
          <SwiperSlide key={employee.name + index} className="d-flex justify-content-center">
            <EmployeeCard employee={employee} />
          </SwiperSlide>
        ))}
      </SwiperComponent>
      <SliderArrow
        orientation="right"
        containerClass={classNames(
          'me-md-0 order-2',
          // Medium
          'me-md-4'
        )}
        style={{ height: `${arrowHeight}px` }}
        disabled={isFirstOrLastIndex === 'last' || screenWidth >= ScreenSize.XXXLarge}
        onClick={handleNextSlide}
      />
    </section>
  );
};
