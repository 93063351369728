import { ICompanyWorkingHours } from 'interfaces/company/working-hours.company.interface';

export const companyWorkingHoursInfo: ICompanyWorkingHours = {
  businessDays: ['segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira'],
  closedDays: ['sábado', 'domingo'],
  hours: {
    morning: {
      startTime: '09:00',
      closeTime: '12:00',
    },
    afternoon: {
      startTime: '13:30',
      closeTime: '17:30',
    },
  },
};
