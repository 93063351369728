import { useCallback, useEffect, useState } from 'react';
import Cookies, { CookieSetOptions } from 'universal-cookie';

interface CookieHook<T> {
  setCookie: (cookieName: string, value: T, options?: CookieSetOptions | undefined) => void;
  getCookie: (cookieName: string) => T | null;
  setConsent: (value: boolean, options?: CookieSetOptions | undefined) => void;
  hasConsent: boolean | undefined | null;
}

export const useCookies = <T>(): CookieHook<T> => {
  const COOKIE_CONSENT_KEY = 'cookieConsent';

  const [cookies, setCookies] = useState(new Cookies());
  const [hasConsent, setHasConsent] = useState<boolean | undefined | null>(null);

  const getCookie = useCallback(
    (cookieName: string) => {
      const cookieValue = cookies.get(cookieName);
      return cookieValue;
    },
    [cookies]
  );

  const setCookie = useCallback(
    (cookieName: string, value: T, options?: CookieSetOptions) => {
      if (!hasConsent) return;

      setCookies((prevCookies) => {
        prevCookies.set(cookieName, JSON.stringify(value), options);
        return new Cookies(prevCookies.getAll());
      });
    },
    [hasConsent]
  );

  const setConsent = useCallback(
    (value: boolean, options: CookieSetOptions | undefined = { path: '/' }) => {
      setCookies((prevCookies) => {
        prevCookies.set(COOKIE_CONSENT_KEY, JSON.stringify(value), options);
        return new Cookies(prevCookies.getAll());
      });
      setHasConsent(value);
    },
    [setCookies]
  );

  useEffect(() => {
    const consentCookieValue = getCookie(COOKIE_CONSENT_KEY);
    if (hasConsent !== consentCookieValue) setHasConsent(consentCookieValue);
  }, [getCookie, hasConsent]);

  return { setCookie, getCookie, setConsent, hasConsent };
};
