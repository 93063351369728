import classNames from 'classnames';
import { SectionBackground } from 'components/section-background/section-background.component';
import { companyInfo } from 'utils/company';
import { TitleContainer } from '../../../components/title-container/title-container';
import { ContactForm } from './contact-form.component';

interface IContact {
  isActive: boolean;
}

export const Contact = ({ isActive }: IContact) => {
  return (
    <section
      id={companyInfo.pages.contact.id}
      className={classNames(
        'd-flex justify-content-center align-items-center position-relative gap-2',
        { 'pt-8': isActive }
      )}
    >
      <SectionBackground backgroundImages={companyInfo.pages.contact.backgroundImages} />
      <TitleContainer
        title={companyInfo.pages.contact.title}
        position="start"
        titleClassName={classNames({ 'pt-8': isActive })}
      >
        <section className="d-flex flex-column align-items-end justify-content-around gap-4 h-100">
          <ContactForm />
        </section>
      </TitleContainer>
    </section>
  );
};
