import classNames from 'classnames';

type TPosition = 'start' | 'end';

interface ITitleContainer {
  title: string;
  position: TPosition;
  children: JSX.Element;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
  isBeingObserved?: boolean;
}

export const TitleContainer = ({
  title,
  position,
  children,
  className,
  titleClassName,
  contentClassName,
}: ITitleContainer): JSX.Element => {
  return (
    <>
      <section
        className={classNames(
          'title-container position-absolute top-0 bottom-0 w-100 d-flex',
          'flex-shrink-0 overflow-hidden',
          // Medium
          `position-md-static w-md-max-content`,
          // Logic
          { 'order-0': position === 'start' },
          { 'order-1': position === 'end' },
          className
        )}
      >
        <section
          className={classNames(
            'title-container--container position-relative flex-fill d-flex',
            'align-items-start justify-content-center',
            // Medium
            `justify-content-md-${position} align-items-md-center`
          )}
        >
          <div
            className={classNames(
              `title-container--bg title-container--bg-${position}`,
              'bg-primary-300 h-100 w-100 position-absolute top-0 bottom-0 opacity-75'
            )}
          />
          <h2
            className={classNames(
              'title-container--title fs-h5 fw-bold font-sans-serif',
              'text-white-300 text-uppercase text-center m-5',
              // Small
              'fs-sm-h4',
              // Medium
              `fs-md-h2 w-md-min-content text-md-${position} m${position[0]}-md-5`,
              titleClassName
            )}
          >
            {title}
          </h2>
        </section>
      </section>
      <section className={classNames('title-container--content flex-fill', contentClassName)}>
        {children}
      </section>
    </>
  );
};
