import { useEffect, useRef } from 'react';

export const useEventListener = (
  eventName: string,
  handler: (event: Event) => void,
  options: boolean | AddEventListenerOptions = {},
  element: HTMLElement | Window = window
) => {
  const savedHandler = useRef<(event: Event) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current?.(event);

    element.addEventListener(eventName, eventListener, options);

    return () => {
      element.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element, options]);
};
