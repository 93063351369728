import classNames from 'classnames';
import { companyInfo } from 'utils/company';
import { Contact } from './components/contact.component';
import { LogoFooter } from './components/logo-footer.component';
import { MapFrame } from './components/map-frame.component';
import { OpeningHours } from './components/opening-hours.component';

export const Footer = () => {
  return (
    <footer
      id={companyInfo.pages.footer.id}
      className={classNames(
        'bg-primary-100 pb-2',
        // Large
        'pb-lg-2'
      )}
    >
      <div className="line pt-3 mb-3" />

      <MapFrame />

      <section
        className={classNames(
          'info container justify-content-center gap-5 my-4',
          // Large
          'd-lg-flex min-vw-lg-auto'
        )}
      >
        <section
          className={classNames(
            'row fs-body4 justify-content-center align-items-start gap-3 ps-3',
            // Small
            'ps-sm-0',
            // Large
            'fs-lg-body3',
            // Extra Large
            'flex-xl-nowrap gap-xl-0'
          )}
        >
          <Contact />
          <OpeningHours />
          <LogoFooter />
        </section>
      </section>

      <p
        className={classNames(
          'fs-body4 text-gray-400 lh-base mt-2 mx-2',
          // Large
          'mt-lg-3 mx-lg-3'
        )}
      >
        {companyInfo.copyright}
      </p>
    </footer>
  );
};
