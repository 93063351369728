import { ICompanyAddress } from 'interfaces/company/address.company.interface';

export const companyAddressInfo: ICompanyAddress = {
  url: 'https://goo.gl/maps/R5KfYYDHhMirfE7a7',
  value: [
    'R. Cel. Procopio G. de Oliveira, 210 - sala 02,',
    'Centro, Jaraguá do Sul, SC,',
    '89251-201',
  ],
  mapSource:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.1577188270026!2d-49.08598150681073!3d-26.482866217360034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94de95b8b6e9b17d%3A0x7ba8fb64fc15e68d!2sLombardi%20%26%20Boeing%20Advogados!5e0!3m2!1spt-BR!2sbr!4v1682474955148!5m2!1spt-BR!2sbr',
};
