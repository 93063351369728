import BackgroundImageDesktop from 'assets/images/carsten-stalljohann-S3UCfaKTNZQ-unsplash-desktop.webp';
import BackgroundImageMobile from 'assets/images/carsten-stalljohann-S3UCfaKTNZQ-unsplash-mobile.webp';
import { INoMatchPageInfo } from 'interfaces/company/pages-info.company.interface';

export const noMatchPageInfo: INoMatchPageInfo = {
  title: '404',
  id: 'no-match',
  backgroundImages: {
    mobileSource: BackgroundImageMobile,
    desktopSource: BackgroundImageDesktop,
    altText: 'Campo de grama verde.',
  },
};
