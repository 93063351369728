/**
 * This function can be used in inputs to format the values as the user types.
 *
 * @param {string} value - The current value of the phone number input.
 * @returns {string} The formatted phone number.
 * @example
 * // Returns "(12) 3 4567-8910"
 * phoneMask("12345678910");
 */
export const phoneMask = (value: string): string => {
  // Remove all non-numeric characters from the input value
  value = value.replace(/\D/g, '');

  // Apply phone number formatting based on the number of characters
  if (value.length <= 1) return value;
  if (value.length === 2) return `(${value}`;
  if (value.length === 3) return `(${value}) `;
  if (value.length === 4) return `(${value.slice(0, 2)}) ${value.slice(2)}`;
  if (value.length === 8) return `(${value.slice(0, 2)}) ${value.slice(2, 3)} ${value.slice(3)}`;
  if (value.length === 12)
    return `(${value.slice(0, 2)}) ${value.slice(2, 3)} ${value.slice(3, 7)}-${value.slice(7)}`;

  return `(${value.slice(0, 2)}) ${value.slice(2, 3)} ${value.slice(3, 7)}-${value.slice(7, 11)}`;
};
