import { companyInfo } from 'utils/company';

export const MapFrame = () => {
  return (
    <iframe
      title="Google maps company location"
      className="map-frame w-100 border-0 bg-white"
      src={companyInfo.address.mapSource}
      allowFullScreen={false}
      loading="lazy"
    ></iframe>
  );
};
