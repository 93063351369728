import classNames from 'classnames';
import { InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  onChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  inputMask?: (text: string) => string;
}

export const Input = ({
  label,
  onChange,
  error = '',
  containerClassName,
  inputMask,
  ...rest
}: InputProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isInputEmpty, setIsInputEmpty] = useState<boolean>(false);

  const handleSetInputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
      if (inputMask) setInputValue(inputMask(e.target.value));
      else setInputValue(e.target.value);
    },
    [onChange, inputMask]
  );

  const inputId = useMemo(() => `input-${label.replaceAll(' ', '-').toLowerCase()}`, [label]);

  useEffect(() => {
    setIsInputEmpty(!!inputValue);
  }, [inputValue]);

  return (
    <div className={classNames('custom-input mt-3', containerClassName)} data-active={isInputEmpty}>
      <div className="position-relative">
        <label
          htmlFor={inputId}
          className={classNames(
            'form-label position-absolute m-0 d-flex h-100 align-items-center fs-body2 pe-none',
            { 'text-primary-500': !isInputEmpty },
            { 'fs-body4 fw-bold text-white pe-auto h-auto': isInputEmpty }
          )}
        >
          {label}
        </label>
        <input
          id={inputId}
          className={classNames(
            'form-control rounded-0 text-primary border border-1 border-transparent',
            { 'border-danger': !!error }
          )}
          onChange={handleSetInputValue}
          aria-labelledby={inputId}
          {...rest}
        />
      </div>

      <p
        className={classNames('invalid-feedback d-block fs-body4 opacity-0 pe-none w-0', {
          'opacity-100 pe-auto w-100': !!error,
        })}
      >
        {error}
      </p>
    </div>
  );
};
