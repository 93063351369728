import { ICompanyInfo } from 'interfaces/company/info.company.interface';
import { companyAddressInfo } from './address.company';
import { companyClientInfo } from './client.company';
import { companyCopyrightInfo } from './copyright.company';
import { companyPagesInfo } from './pages.company';
import { companySocialsInfo } from './socials.company';
import { companyWorkFieldsInfo } from './work-fields.company';
import { companyWorkingHoursInfo } from './working-hours.company';

export const companyInfo: ICompanyInfo = {
  address: companyAddressInfo,
  socials: companySocialsInfo,
  workFields: companyWorkFieldsInfo,
  copyright: companyCopyrightInfo,
  workingHours: companyWorkingHoursInfo,
  pages: companyPagesInfo,
  client: companyClientInfo,
};
