import { useEffect, useState } from 'react';
import { companyInfo } from 'utils/company';

export const useIntersectionObserver = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute('id');
          setActiveSection(sectionId);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    });

    const sectionIds = Object.values(companyInfo.pages).map((page) => `#${page.id}`);
    document.querySelectorAll(sectionIds.join(', ')).forEach((section) => {
      observer.observe(section);
    });

    return () => {
      observer.disconnect();
    };
  }, [activeSection]);
  return { activeSection };
};
