import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { companyInfo } from 'utils/company';
import { checkBusinessHoursTime, checkTimeBetweenTwoTimes } from 'utils/date';

const TIME_MS = 1000; // 1 second in milliseconds

const getWeekDay = (date: Date) =>
  date.toLocaleString('pt-br', {
    weekday: 'long',
  });

export const OpeningHours = () => {
  const { businessDays, closedDays, hours } = companyInfo.workingHours;
  const [dateNow, setDateNow] = useState<Date>(new Date());

  const weekDayNow = useMemo<string>(() => getWeekDay(dateNow), [dateNow]);
  const weekDays = useMemo<string[]>(
    () => [...businessDays, ...closedDays],
    [businessDays, closedDays]
  );
  const [contentTooltip, setContentTooltip] = useState<string>('');

  const checkValidDay = useCallback(() => {
    const isValidTime = checkBusinessHoursTime(dateNow, companyInfo.workingHours.hours);
    const isValidDay = companyInfo.workingHours.businessDays.includes(weekDayNow);
    const isItOpenOrClosed = isValidDay && isValidTime ? 'abertos' : 'fechados';

    let text = `Hoje (${weekDayNow}) estamos ${isItOpenOrClosed}.`;
    if (isValidTime) {
      const timeNow = `${dateNow.getHours()}:${dateNow.getMinutes()}`;
      text = `Hoje (${weekDayNow}) às ${timeNow} estamos ${isItOpenOrClosed}.`;
    }

    setContentTooltip(text);
  }, [dateNow, weekDayNow]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Gets weekday in brazilian long format string
      setDateNow(new Date());
      checkValidDay();
    }, TIME_MS);

    return () => clearInterval(interval);
  }, [checkValidDay]);

  const periodSpan = useCallback(
    (period: 'morning' | 'afternoon', weekDay: string) => {
      const startTime = hours[period].startTime;
      const closeTime = hours[period].closeTime;

      const isValidNow = checkTimeBetweenTwoTimes(dateNow, startTime, closeTime);
      const isValidTimeAndWeekDay = isValidNow && weekDay === weekDayNow;

      return (
        <span className={classNames({ 'text-white': isValidTimeAndWeekDay })}>
          {startTime}-{closeTime}
        </span>
      );
    },
    [hours, weekDayNow, dateNow]
  );

  return (
    <section
      className={classNames(
        'col-3 d-flex flex-column text-white gap-2',
        // Small
        'col-sm-3',
        // Large
        'col-lg-2'
      )}
    >
      <h4 className="text-uppercase">Horário de Funcionamento</h4>
      <ButtonToolbar>
        <OverlayTrigger overlay={<Tooltip>{contentTooltip}</Tooltip>}>
          <div className="d-flex flex-column gap-1">
            {weekDays.map((weekDay, index) => {
              return (
                <p key={index + weekDay} className="d-flex gap-2 text-gray-300">
                  <span
                    className={classNames('weekdays text-capitalize', {
                      'text-white': weekDay === weekDayNow,
                    })}
                  >
                    {weekDay}
                  </span>

                  <span className="no-wrap">
                    {businessDays.includes(weekDay) ? (
                      <>
                        {periodSpan('morning', weekDay)}, {periodSpan('afternoon', weekDay)}
                      </>
                    ) : (
                      'Fechado'
                    )}
                  </span>
                </p>
              );
            })}
          </div>
        </OverlayTrigger>
      </ButtonToolbar>
    </section>
  );
};
