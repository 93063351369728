import { ScreenSize } from 'hooks/use-screen-size.hook';
import { IEmployeeInfo } from 'interfaces/employee/employee.interface';
import { openLink } from 'utils/open-link';

interface EmployeeCardProps {
  employee: IEmployeeInfo;
}

export const EmployeeCard = ({ employee }: EmployeeCardProps) => {
  return (
    <section className="employee-card d-flex align-items-center justify-content-center position-relative overflow-hidden h-fit-content w-fit-content shadow">
      <picture className="w-100 h-100">
        <source
          media={`(max-width: ${ScreenSize.Small}px)`}
          srcSet={employee.image.mobile}
          className="w-100 h-100"
        />
        <source
          media={`(min-width: ${ScreenSize.Small + 1}px)`}
          srcSet={employee.image.desktop}
          className="w-100 h-100"
        />
        <img
          src={employee.image.desktop}
          alt={employee.image.alt}
          loading="lazy"
          className="w-100 h-100"
        />
      </picture>
      <section className="employee-card--content p-4 position-absolute end-0 bottom-0 start-0 overflow-hidden" />
      {!!employee.socials && (
        <section className="employee-card--content-socials position-absolute d-flex flex-column gap-2">
          {Object.entries(employee.socials).map(([name, { url, icon: Icon }], index) => (
            <button
              key={name + index}
              className="btn bg-primary p-2 rounded-0 shadow-lg"
              title={`Perfil do ${name} de ${employee.name}`}
              onClick={() => openLink(url)}
            >
              <Icon className="fill-white" />
            </button>
          ))}
        </section>
      )}
      <section className="employee-card--content-info position-absolute text-white">
        <p className="name font-serif fs-h4 mb-2 me-2">{employee.name}</p>
        <p className="role fs-h6">{employee.role}</p>
      </section>
    </section>
  );
};
