import { ReactComponent as LogoBigBottomOab } from 'assets/logo/logo-big-bottom-oab.svg';
import { ReactComponent as LogoBigOab } from 'assets/logo/logo-big-oab.svg';
import { ReactComponent as LogoBigWrappedBottomOab } from 'assets/logo/logo-big-wrapped-bottom-oab.svg';
import { ReactComponent as LogoSmall } from 'assets/logo/logo-small.svg';
import classNames from 'classnames';

interface ILogo {
  size: 'small' | 'big' | 'big-wrapped';
  oab?: boolean;
  text?: '' | 'right' | 'bottom';
  className?: string;
}

export const Logo = ({ size = 'small', text = '', oab = false, className }: ILogo) => {
  let LogoElement: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > = LogoSmall;

  if (size === 'big' && oab) {
    LogoElement = LogoBigOab;

    if (text === 'bottom') LogoElement = LogoBigBottomOab;
  }

  if (size === 'big-wrapped' && oab && text === 'bottom') LogoElement = LogoBigWrappedBottomOab;

  return <LogoElement className={classNames('w-100 h-100', className)} />;
};
