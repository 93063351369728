import { RoutePath } from 'navigation/route-path';
import { LandingPage } from 'pages/landing/landing.page';
import { NoMatchPage } from 'pages/no-match/no-match.page';
import { PrivacyPolicyPage } from 'pages/privacy-policy/privacy-policy.page';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutePath.NoMatch} element={<NoMatchPage />} />
        <Route path={RoutePath.Home} element={<LandingPage />} />
        <Route path={RoutePath.PrivacyPolicy} element={<PrivacyPolicyPage />} />
      </Routes>
    </BrowserRouter>
  );
}
