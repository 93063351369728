import { ScreenSize } from 'hooks/use-screen-size.hook';
import { IBackgroundImages } from 'interfaces/company/pages-info.company.interface';

interface ISectionBackground {
  backgroundImages: IBackgroundImages;
}

export const SectionBackground = ({ backgroundImages }: ISectionBackground) => {
  return (
    <picture className="section-background position-absolute top-0 left-0 w-100 h-100">
      <source
        media={`(max-width: ${ScreenSize.Small}px)`}
        srcSet={backgroundImages.mobileSource}
        className="w-100 h-100"
      />
      <source
        media={`(min-width: ${ScreenSize.Small + 1}px)`}
        srcSet={backgroundImages.desktopSource}
        className="w-100 h-100"
      />
      <img
        src={backgroundImages.desktopSource}
        alt={backgroundImages.altText}
        loading="lazy"
        className="w-100 h-100"
      />
    </picture>
  );
};
