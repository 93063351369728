import classNames from 'classnames';
import { SectionBackground } from 'components/section-background/section-background.component';
import { TitleContainer } from 'components/title-container/title-container';
import { WorkField } from 'components/work-field/work-field';
import { companyInfo } from 'utils/company';
import { getInitials } from 'utils/string';

interface IWorkFields {
  isActive: boolean;
}

export const WorkFields = ({ isActive }: IWorkFields) => {
  const { workFields } = companyInfo;

  return (
    <section
      id={companyInfo.pages.workFields.id}
      className={classNames(
        'd-flex justify-content-center align-items-center position-relative gap-2',
        { 'pt-8': isActive }
      )}
    >
      <SectionBackground backgroundImages={companyInfo.pages.workFields.backgroundImages} />
      <TitleContainer
        title={companyInfo.pages.workFields.title}
        position="start"
        titleClassName={classNames({ 'pt-8': isActive })}
        contentClassName={classNames(
          'mt-9',
          // Medium
          'pt-sm-0 mt-sm-0'
        )}
      >
        <section
          className={classNames(
            'd-flex flex-column align-items-end justify-content-center gap-3 h-100',
            // Medium
            'gap-md-7'
          )}
        >
          {workFields.map(({ name, description, initials }, index) => (
            <WorkField
              key={name + index}
              name={name}
              description={description}
              initials={initials ?? getInitials(name)}
            />
          ))}
        </section>
      </TitleContainer>
    </section>
  );
};
