import { ICompanyPagesInfo } from 'interfaces/company/pages-info.company.interface';
import {
  aboutUsPageInfo,
  contactPageInfo,
  footerPageInfo,
  initialPageInfo,
  teamPageInfo,
  workFieldsPageInfo,
} from './pages';
import { noMatchPageInfo } from './pages/no-match.page.company';

export const companyPagesInfo: ICompanyPagesInfo = {
  initial: initialPageInfo,
  aboutUs: aboutUsPageInfo,
  workFields: workFieldsPageInfo,
  team: teamPageInfo,
  contact: contactPageInfo,
  footer: footerPageInfo,
  noMatch: noMatchPageInfo,
};
