import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as WhatsappIcon } from 'assets/icons/whatsapp.svg';
import { ICompanySocials } from 'interfaces/company/socials.company.interface';

export const companySocialsInfo: ICompanySocials = {
  instagram: {
    url: 'https://www.instagram.com/lombardi.boeing.adv/',
    username: 'lombardi.boeing.adv',
    icon: InstagramIcon,
  },
  linkedin: {
    url: 'https://www.linkedin.com/company/lombardiboeing/',
    username: 'lombardiboeing',
    icon: LinkedinIcon,
  },
  whatsapp: {
    url: 'https://wa.me/message/OFTWSTV6OSG5P1',
    number: '+55 (47) 3017-8609',
    icon: WhatsappIcon,
  },
  email: 'atendimento@lombardiboeing.com.br',
};
