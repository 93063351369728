import classNames from 'classnames';
import { SectionCarouselBackground } from 'components/section-background/section-carousel-background.component';
import { useScrollPosition } from 'hooks/use-scroll-position.hook';
import { useMemo, useRef } from 'react';
import { companyInfo } from 'utils/company';
import { AboutUsBackgroundLines } from './components/about-us-background-lines.component';

export const AboutUs = () => {
  const aboutUsSectionRef = useRef<HTMLElement>(null);

  const { getScrollPercentageFromElement } = useScrollPosition();
  const scrollPercentage = getScrollPercentageFromElement(aboutUsSectionRef);

  // Determine if the section is considered active based on the scroll percentage.
  // The activeSection value will be true if the scrollPercentage is equal to or greater than 0.5 (50%).
  const activeSection = useMemo(() => scrollPercentage * 2 >= 1, [scrollPercentage]);

  return (
    <section
      id={companyInfo.pages.aboutUs.id}
      ref={aboutUsSectionRef}
      className={classNames(
        'min-vh-100 d-flex justify-content-center align-items-center position-relative overflow-hidden bg-primary-500',
        { active: activeSection }
      )}
    >
      <SectionCarouselBackground backgroundImages={companyInfo.pages.aboutUs.backgroundImages} />
      <AboutUsBackgroundLines scrollPercentage={scrollPercentage} />
      <section
        className={classNames(
          'text-content col-10',
          // Small
          'col-sm-8',
          // Large
          'col-lg-6'
        )}
      >
        <h3
          className={classNames(
            'title text-white',
            // Gets the scroll value and multiplies by 100 to use the
            // proper opacity class, and multiplies by 3 so the
            // text is visible before finishing the whole animation
            `opacity-${Math.round(scrollPercentage * 100 * 3)}`,
            { 'fs-h3 mb-3': activeSection }
          )}
        >
          Quem somos
        </h3>

        <section
          className={classNames('opacity-0 overflow-auto', {
            'opacity-100': activeSection,
          })}
        >
          <section className={'d-flex flex-column gap-3 text-white fs-md-h5 pe-1 pe-md-0'}>
            <p>
              A Lombardi & Boeing Advogados Associados é um escritório de advocacia com mais de uma
              década de experiência, com atuação nas áreas do Direito Imobiliário, Ambiental,
              Corporativo, de Família e Sucessões e em questões patrimoniais diversas, tendo seu
              trabalho pautado em uma abordagem centrada no cliente, priorizando as necessidades e
              interesses de nossos clientes.
            </p>
            <p>
              Possuímos uma ampla rede de parceiros aptos a auxiliar os clientes nas mais diversas
              necessidades do dia a dia, permitindo-nos prestar serviços abrangentes e de qualidade.
            </p>
            <p>
              Nossa equipe está em constante atualização e desenvolvimento profissional, mantendo-se
              sempre atenta às últimas tendências e evoluções na área jurídica, visando a aplicação
              do melhor conhecimento aos casos que são confiados.
            </p>
            <p>
              Na Lombardi & Boeing Advogados Associados, estamos comprometidos em fornecer
              aconselhamento jurídico claro, preciso e acessível, permitindo que nossos clientes
              tomem decisões informadas e confiantes.
            </p>
          </section>
        </section>
      </section>
    </section>
  );
};
